import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from "react"
import slugify from "slugify"
import MetaHead from "../components/MetaHead";
import {Link} from "gatsby"
import Layout from "../components/Layout";
import GatsbyImage from 'gatsby-image';
import {graphql} from "gatsby"

let headings = [];


const Article = ({data, pageContext, path}) => {
	headings = []
	const article = data.contentfulBlogPost;
	const essentialData = data.contentfulEssentialData
	const document = JSON.parse(article.content.raw)

	console.log(path)


	const options = {
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				const id = node.data.target.sys.id
				const image = article.content.references.find((entry) => entry.contentful_id === id)

				if(image.file.contentType === "image/svg+xml"){
					return <img src={image.file.url} alt={image.title} />
				}else {
					return <GatsbyImage fluid={image.fluid} alt={image.title} />
				}
			},
			[BLOCKS.EMBEDDED_ENTRY]: (node) => {
				if(node.nodeType === "embedded-entry-block"){
					const id = node.data.target.sys.id
					const element = article.content.references.find((entry) => entry.contentful_id === id)

					return <div dangerouslySetInnerHTML={{__html: element.markdown.childMarkdownRemark.html}}></div>
				}
			},
			[BLOCKS.HEADING_2]: (node) => {
				const nodeContent = node.content.filter((el) => el.nodeType === "text" && el.value)[0].value
				const anchor = slugify(nodeContent.toLowerCase())

				headings.push({
					text: nodeContent,
					anchor: anchor
				})
				/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
				return <h2 key={anchor} aria-details={`Copy Headline: ${nodeContent}`} tabIndex={0} role="button" id={anchor} onClick={(e) => doCopy(e, anchor, path)} onKeyDown={(e) => {if(e.key === "Enter") doCopy(e, anchor, path)}}>{nodeContent}</h2>;
				/* eslint-enable jsx-a11y/no-noninteractive-element-to-interactive-role */
			}
		}
	}

	return (
		<>
			<MetaHead meta={{
					title: article.title,
					description: {description: article.excerpt.excerpt},
					og_type: "article",
					ob_image: article.blog_thumbnail
			}}  lang={pageContext.locale} path={path} />
			<Layout essentialData={{...essentialData, langKey: pageContext.locale, path}}>
				<div className="breadcrumbs">
					<Link to={`/${pageContext.locale}`}>Home</Link> &gt; <Link to={`/${pageContext.locale}/blog/`}>Blog</Link> &gt; {article.title}
				</div>
				<h1>{article.title}</h1>
				<span className="subtitle">{article.subtitle.subtitle}</span>
				<div className="blog_container">
					{/* <div className="blog_content">{RichText.render(article.content)}</div> */}
					<div className="blog_content">{documentToReactComponents(document, options)}</div>
					{/* Ein Inhaltsverzeichnis ist ohne weiteres nicht mit Prismic möglich */}
					<div className="sideMenu">
						<div className="floater">
							<p className="table_of_content_header">Table of Content</p>
							<ol>
								{
									headings.map((heading) => {
										return (
											<li key={heading.anchor}><a href={`#${heading.anchor}`}>{heading.text}</a></li>
										)
									})
								}
							</ol>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
}

const doCopy = async (e, anchor, path) => {
	e.target.classList.add("animated", "tada")

	// Hier muss dann noch die neueste URL rein.
	const url = `https://ericwaetke.com${path}#${anchor}`
	navigator.clipboard.writeText(url)
}


export const query = graphql`
query($slug: String!, $locale: String!) {
	contentfulBlogPost(node_locale: {eq: $locale}, slug: {eq: $slug}) {
		content {
			raw
			references {
				... on ContentfulEmbedElement {
					contentful_id
					markdown {
					  	markdown
						childMarkdownRemark {
							html
						}
					}
				}
				... on ContentfulAsset {
					contentful_id
					title
					fluid(quality:85) {
						...GatsbyContentfulFluid
					}
					file {
						url
						contentType
					}
				}
			}
		}
		date(formatString: "DD.MM.YYYY")
		subtitle {
			subtitle
		}
		title
		excerpt {
			excerpt
		}
	}
	contentfulEssentialData(node_locale: {eq: $locale}) {
		workTitle
		resourcesTitle
		footerCopyright
		footerContact
		blogTitle
		aboutTitle
		homeTitle
		imprintTitle
	}
}`

export default Article;